/**
* Satoshi Light 
* Satoshi LightItalic 
* Satoshi Regular 
* Satoshi Italic 
* Satoshi Medium 
* Satoshi MediumItalic 
* Satoshi Bold 
* Satoshi BoldItalic 
* Satoshi Black 
* Satoshi BlackItalic
*/
@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../assets/fonts/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
