.Home {
  .hero-text {
    transition: opacity 0.1s, font-size 0.2s 0.1s ease-in-out, margin 0.2s 0.1s ease-in-out, height 0.2s 0.1s ease-in-out, padding 0.2s 0.1s ease-in-out;

    &.hide {
      user-select: none;
      opacity: 0 !important;
      font-size: 0 !important;
      margin-top: 0 !important;
      padding: 0 !important;
      margin-bottom: -48px !important;
    }
  }

  .product-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;

    .main-image {
      grid-area: 1 / 1 / 5 / 5;
    }

    .secondary-image {
      &:nth-child(1) {
        grid-area: 1 / 5 / 2 / 6;
      }

      &:nth-child(2) {
        grid-area: 2 / 5 / 3 / 6;
      }

      &:nth-child(3) {
        grid-area: 3 / 5 / 4 / 6;
      }

      &:nth-child(4) {
        grid-area: 4 / 5 / 5 / 6;
      }
    }
  }

  .images-list {
    img {
      height: 71px;
      width: 71px;
    }
  }


  #searched-product {
    animation: item-appear 0.2s 0s ease-out backwards;
  }


  .result {
    animation: item-appear 0.2s 0s ease-out backwards;
    transition: background-color 0.2s, border 0.4s;

    &:active {
      transition: background-color 0.07s, border 0.2s;
    }

    @for $i from 1 through 50 {
      &:nth-of-type(#{$i}) {
        animation-delay: calc(#{$i} * 0.05s);
      }
    }
  }


  @keyframes item-appear {
    0% {
      opacity: 0;
      transform: scale(0.98);
    }
  }
}

@media screen and (max-width: 600px) {
  .Home {
    .product-images {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 8px;
      grid-row-gap: 8px;

      .main-image {
        grid-area: 1 / 1 / 4 / 4;
      }

      .secondary-image {
        &:nth-child(1) {
          grid-area: 1 / 4 / 2 / 5;
        }

        &:nth-child(2) {
          grid-area: 2 / 4 / 3 / 5;
        }

        &:nth-child(3) {
          grid-area: 3 / 4 / 4 / 5;
        }

        &:nth-child(4) {
          grid-area: 2 / 4 / 3 / 5;
        }
      }

      .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 12px;
        grid-row-gap: 12px;
      }

      .div1 { grid-area: 1 / 1 / 4 / 4; }
      .div2 { grid-area: 1 / 4 / 2 / 5; }
      .div3 { grid-area: 2 / 4 / 3 / 5; }
      .div4 { grid-area: 3 / 4 / 4 / 5; }
    }
  }
}
