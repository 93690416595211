.HomeBackground {
  animation: appear 0.2s 0.5s backwards 1 ease-out;
  transform-origin: 50% 50%;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
}
