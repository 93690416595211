
@use "tailwind";
@use "fonts";
@use "button";
@use "../components/SearchBar/SearchBar";
@use "../components/LoadingSearch/LoadingSearch";
@use "../components/HomeBackground/HomeBackground";
@use "../components/Result/Result";
@use "../pages/Home/Home";
@use "../pages/TermsAndConditions/TermsAndConditions";
@use "../pages/PrivacyPolicy/PrivacyPolicy";
@use "../pages/About/About";
@use "../pages/Community/Community";
@use "../pages/Faq/Faq";
@use "../assets/font-awesome.min.css";


body {
  margin: 0;
  font-family: 'Satoshi', 'Ubuntu', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-background;
  @apply text-text;
}

* {
  box-sizing: border-box;
}


button, input {
  @apply text-black;
}
