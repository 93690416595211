.Result {

  .price-button {
    &.cheaper {
      .left {
        @apply bg-primary;
      }

      .right {
        @apply border-primary;
      }
    }

    &.same-price {
      .right {
        @apply rounded-lg;
      }
    }
  }
}
