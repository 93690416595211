.LoadingSearch {
  overflow: hidden;
  animation: appear 0.5s 0s linear;


  &:before {
    position: absolute;
    display: block;
    content: " ";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(0);
    height: calc(100% + 2000px);
    width: calc(100% + 2000px);
    background: conic-gradient(rgba(235, 255, 0, 1) 0%, rgba(150, 150, 150, 1) 15%, rgba(112, 112, 112, 1) 20%, rgba(112, 112, 112, 1) 80%, rgba(150, 150, 150, 1) 85%, rgba(235, 255, 0, 1) 100%);
    animation: rotate-z 2s linear infinite;
  }

  &:after {
    position: absolute;
    display: block;
    content: " ";
    top: 1.5px;
    left: 1.5px;
    height: calc(100% - 3px);
    width: calc(100% - 3px);
    @apply bg-card-background;
    border-radius: calc(0.75rem + 3px);
  }


  .char {
    animation: char-appear 2s 0s linear infinite backwards;

    @for $i from 1 through 200 {
      &:nth-of-type(#{$i}) {
        animation-delay: calc(#{$i} * 0.02s);
      }
    }
  }

  &.error {
    @apply text-base;
    &:before, &:after, .char {
      animation: none !important;
    }

    &:before {
      background: #FF7F7E;
    }
    @apply text-s-tier;
  }
}


@keyframes char-appear {
  0% {
    @apply text-text;
  }
  25% {
    @apply text-text-20;
  }
  50% {
    @apply text-text;
  }
  75% {
    @apply text-text;
  }
  100% {
    @apply text-text;
  }
}


@keyframes rotate-z {
  0% {
  }
  25% {
    //transform: translate(-45%, -50%) rotateZ(90deg);
  }
  50% {
    //transform: translate(-50%, -50%) rotateZ(180deg);
  }
  75% {
    //transform: translate(-55%, -50%) rotateZ(270deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}


@keyframes appear {
  0% {
    opacity: 0;
  }
}
