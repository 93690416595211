.Faq {
  ul {
    @apply list-disc list-outside ml-4;
  }
}


.faq-item {


  .answer {
    font-size: 0;
    opacity: 0;
    padding: 0;
    transition: opacity 0.1s, padding 0.1s 0.1s, font-size 0.1s 0.1s;
  }

  &.open {
    .answer {
      @apply md:px-5 pb-4 text-sm opacity-100;
      transition: opacity 0.1s 0.1s, padding 0.1s, font-size 0.1s;
    }
  }
}

