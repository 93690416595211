form {
  position: relative;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);

  &::after {
    display: block;
    content: " ";
    position: absolute;
    @apply bg-text/30 backdrop-blur-md;
    border-radius: 15px;
    top: 1px;
    left: 1px;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
  }

  * {
    z-index: 1;
    position: relative;
  }
}
